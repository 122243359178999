<script setup lang="ts">
import type { FormGroupType, InputType } from '~/components/inputs/GenericInput.vue'

const props = defineProps<
  {
    name: string
    input?: InputType
    secondary?: boolean
  } & FormGroupType
>()

const inputProps = computed(() => ({
  type: 'text',
  ...props.input,
  inputClass: props.secondary ? 'bg-neutral-100 rounded-none ring-0 p-2' : '',
}))
</script>

<template>
  <AlGenericInput :input="inputProps" :name="name" />
</template>
